<template>
	<div class="row">
		<div class="col-12">
			<h3>{{ $t('monte.congelation.select_actes_delete') }} :</h3>
		</div>
		<div class="col-12">
			<CustomTable
				ref="table"
				id_table="acte_to_delete"
				:items="actes"
				:preselected_items="actes"
				primaryKey="actes_id"
				:useTransformerSort="true"
				:buttonActionEventOnly="true"
				:busy.sync="table_busy"
			/>
        </div>
        <div class="text-center mt-3 col-12">
            <b-button @click="goToDeleteCampagne" variant="primary">
                <font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /><font-awesome-icon v-else :icon="['fal', 'trash-alt']" /> {{ $t('monte.congelation.supprimer_campagne') }}
            </b-button>
        </div>
	</div>
</template>

<script type="text/javascript">
    import Navigation from "@/mixins/Navigation.js"
    import Actes from "@/mixins/Actes.js"
    import Congelation from "@/mixins/Congelation.js"
	import Shutter from '@/mixins/Shutter.js'
	import { EventBus } from "EventBus"

	export default {
		name: 'DeleteCampagne',
		mixins: [Navigation, Actes, Congelation, Shutter],
		props: ['campagne'],
		data () {
			return {
				actes: [],
				table_busy: false,
				processing: false,
				actes_type_test: [
	                "TEST_NEW2_MTRITE_CONTAGIEUSE_QUINE_MEC_POUR_TALON",
	                "TEST_NEW2_ARTRITE_VIRALE_QUINE_EVA_POUR_TALON",
	                "TEST_NEW2_ANMIE_INFCTIEUSE_DES_EQUIDS_AIE",
	                "TEST_DOURINE",
	                "TEST_MORVE"
	            ],
				events_tab: {
					'TableAction::DeleteActes::Trigger': this.goToDeleteActes,
				},
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.table_busy = true
				const actes = await this.getActesByHorses([this.campagne.campagne_horse])
				this.actes = actes.filter(a => a.statut == 0).filter(a => this.actes_type_test.includes(a.actetype.actestype_code))
				this.table_busy = false
			},

			async goToDeleteActes(actes) {
				await this.applyDeleteActes(actes)
				EventBus.$emit('TableAction::stopSpin', true)
				this.init_component()
			},

			async goToDeleteCampagne() {
				this.processing = true
				await this.deleteCampagne(this.campagne.campagne_horse, this.campagne.campagne_id)
				this.ok()
				this.shutterPanel().keepFirst()
			}
		},

		components: {
			CustomTable : () => import('GroomyRoot/components/Table/CustomTable')
		}
	}

</script>